.kyc-container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;

  .kyc-container-label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
  }

  .kyc-container > em {
    font-size: 0.8rem;
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    border-radius: 2px;
    border-color: #fff;
    border-style: dashed;
    background-color: #262525;
    color: #fff;
    outline: none;
    transition: border 0.24s ease-in-out;
    padding-top: 56.25%;
  }

  .dropzone:focus {
    border-color: #ee7268;
  }

  .dropzone.disabled {
    opacity: 0.6;
  }

  #display-error {
    background: transparent !important;
    color: #ee7268 !important;
    padding: 20px;
    padding-bottom: 20px;
    position: absolute;
    top: 0;
    width: 100%;
    h1 {
      font-size: 20px;
    }
  }

  #outer-circle {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .expand {
    transform: rotate(0deg);
    margin-left: auto;
    transition: transform 0.3s ease-in;
  }

  .expandOpen {
    transform: rotate(180deg);
  }

  .react-html5-camera-photo {
    position: relative;
    border-width: 2px;
    border-radius: 2px;
    border-color: #fff;
    border-style: dashed;
    background-color: #262525;
    padding-top: 56.25%;
  }

  .react-html5-camera-photo > img,
  .react-html5-camera-photo > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .spin {
    animation: spin 4s linear 2s infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}